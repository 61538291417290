body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.body-content { 
	max-width: 500px;
  	margin: auto;
  	background: white;
}  



.css-18sg6k4-MuiPaper-root-MuiDrawer-paper {
	position: fixed !important;
}
 

.container-2-column {
	display: grid;
	grid-template-columns: 1fr;
	
	padding:  10px;
	grid-row-gap: 20px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	

}

.square-img {
	position: relative;
	width: 100%;
	
	padding-top: 100%;
}
.item {
	display: grid;
	grid-template-columns: 3fr 1fr;
	
	padding:  10px;
	grid-row-gap: 20px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
}

.item >img {
	/* width: 100%;
	height: 1fr;
	border-radius: 3%;
	object-fit: cover; */

	position: absolute; /* Take your picture out of the flow */
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; /* Make the picture taking the size of it's parent */
	width: 100%; /* This if for the object-fit */
	height: 100%; /* This if for the object-fit */
	object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
	object-position: center;
	border-radius: 3%;
}

.item > a > img {
	width: 100%;
	border-radius: 3%;
}


.page-title {
	padding: 20px;
	font-size: 1.5rem;
	font-weight: bold;

}

.item-title {
	margin-top: 1rem;
	font-size: larger;
	font-weight: bold;
}

.item-desc {
	width: 100%;
	font-size: 0.8rem;
	/* white-space: wrap; */
  	overflow: hidden;
  	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

}

.item-product {
	display: grid;
	grid-template-columns: 1fr;
	font-weight: normal;
}
.price {
	display: flex
}
.item-price {
	font-weight: bold;
	font-size: .9rem;
}

.item-price-discount {
	font-weight: normal;
	text-decoration: line-through;
	font-size: .9rem;
	padding-left: 1rem;
	margin-bottom: .5rem;
	color: red
}
.btn-cart {
	margin-bottom: 1rem !important;
}

.item-outlet {
	font-size: .8rem;
	font-style: italic;
}

.item-stock {
	font-size: .8rem;
	padding-bottom: 1rem;
}
.cart-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	
	padding:  10px;
	grid-row-gap: 20px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
}

.cart-image > img {
	width: 100%;
	border-radius: 3%;

}

.c-price {
	font-size: 1.2rem;
	font-weight: bold;
}

.c-item {
	font-style: italic;
}

.summary-label {
	font-weight: bold;

}
.summary-price {
	font-size: 1.5rem;
	font-weight: bold;
}

.btn-control {
	min-width: 30px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.summary-container {
	padding: 1rem
}

.i-data-container {
	display: grid;
	grid-template-columns: 1fr 4fr;
	
	padding:  5px;
	grid-row-gap: 10px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	border-bottom-style: solid !important;
	border-bottom: 1px;
	border-bottom-color: grey;
}

.i-data-image>img {
	width: 100%;
	border-radius: 10%;
}

.item-subtotal {
	text-align: right;
}
.item-subtotal-label {
	font-size: .8rem;
}
.item-subtotal-value {
	font-size: 1rem;
	font-weight: bold;
}

.item-name {
	font-weight: bold;
}
.total-payment{
	display: grid;
	grid-template-columns: 1fr 1fr;
	
	padding:  5px;
	grid-row-gap: 10px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	/* border-bottom-style: solid !important;
	border-bottom: 1px;
	border-bottom-color: grey; */
}
.total-payment-label {
	font-size: .8rem;
	font-weight: bold;
}

.total-payment-value {
	font-size: 1rem;
	font-weight: bold;
	text-align: right;
}

.history-title-container {
	display: grid;
	grid-template-columns: .2fr 3fr 2fr;
	
	padding:  10px;
	grid-row-gap: 10px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
}
.history-icon {
	margin: 0;
 	top: 50%;
}

.history-shoping-label {
	font-weight: bold;
	font-size: 1rem;

}

.history-date {
	font-style: italic;
	font-size: .8rem;
}

.history-shoping-status {
	text-align: right;
}

.history-item {
	
	padding:  10px; 
	border-style: solid;
	border-width: 1px;
	border-color: grey;
	border-radius: 5px;
	margin: 1rem;
	
}


.status-pending {
	text-align: center;
	vertical-align: middle;
	color: #b5a200;
	font-weight: bold; 
	text-align: right;
}

.status-approved {
	text-align: right;
	vertical-align: middle;
	color: #0eb933;
	font-weight: bold; 
}

.status-rejected {
	text-align: right;
	vertical-align: middle;
	color: #8d1332bd;
	font-weight: bold; 
}

.title-page {
	font-size: large;
	font-weight: bold;
	text-transform: uppercase;
	/* margin-bottom: 1rem; */
}

.image-gallery-image {
	height: 400px !important;
}

.product-container {
	margin: 1rem;
}
.product-title {
	margin-top: 1rem;
	font-size: larger;
	font-weight: bold;
}

.label-info {
	font-size: 0.7rem;
	font-style: italic;
}

.item-full-desc {
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: justify;
  	text-justify: inter-word;

}

.item-full-desc-sub {
	font-size: 0.9rem;
	font-style: italic;
	margin-bottom: 1rem;
}
.label-title {
	
	font-size: 0.8rem;
	font-weight: bold;
}

.item-sub-title {
	font-size: large;
	font-weight: bold;
}

.btn {
	background-color: #199319;
	color: white;
	padding: 15px 25px;
	text-decoration: none;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	margin-bottom: 5rem;
}

.item-link {
	margin-bottom: 20px;
}

.sub-title-page {
	font-size: .8rem;
	font-weight: bold;
	font-style: italic;
}

.voucher-title {
	font-weight: bold;
}

.datepicker-modal {
	position: fixed !important;
}

.history-price {
	font-weight: bold;
	color: #199319;
	font-size: 1.5rem;
}

.store-profile-container{
	display: grid;
	grid-template-columns: .2fr 1fr;
	
	padding:  10px;
	grid-row-gap: 10px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
}

.profile-logo > img {
	width: 100%;
	border-radius: 5%;
}

.profile-company {
	font-size: 1.3rem;
	font-weight: bold;
}
.profile-address {
	font-size: .6rem;
}

.container-2c {
	display: grid;
	grid-template-columns: 1fr 1fr;
	
	padding:  10px;
	grid-row-gap: 10px;
	column-gap: 20px;

    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
}

.item-2c > img {
	width : 100%;
	
	
}

.product-name {
	font-weight: bold;
}

.iconinvert{
	width: 24px;
	filter: invert(100%)
}